.page {
  max-width: 65ch;
  width: fit-content;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: var(--spacing-xl);
}

.links_wrapper {
  display: flex;
  text-decoration: underline;
  text-underline-offset: 0.25rem;
  gap: var(--spacing-xl);
}
