.shared {
  word-break: break-word;
}

.h1 {
  font: var(--h1);
}

.h2 {
  font: var(--h2);
}

.h3 {
  font: var(--h3);
}

.h4 {
  font: var(--h4);
}

.tiered {
  font: var(--h4);
  line-height: 100%;
  text-align: left;

  & * > b {
    font: 400 2rem/1.2 'ivyjournal';
    line-height: 100%;
    display: block;
  }
}

.clickable {
  &:hover {
    cursor: pointer;
    cursor: hand;
  }
}
